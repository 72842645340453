<template>
  <div>
    <!-- <iframe id="iframe" style="width: 100%; height: 1000px;"></iframe> -->
  </div>
</template>

<script>
// import Axios from "axios";

import { degrees, PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import image from "../../../assets/img/new-certificado.png";
import unb from "../../../assets/font/unb_pro_bold.otf";
import unbNormal from "../../../assets/font/unb.ttf";


const fetchBinaryAsset = asset => fetch(asset).then(res => res.arrayBuffer());

export default {
  props: {
    user: Object
  },
  data() {
    return {
      pdf: null
    };
  },
  methods: {
    renderInIframe(pdfBytes) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      this.pdf = blobUrl;
      a.href = blobUrl;
      a.download = "certificado.pdf";
      a.click();
      // document.getElementById("iframe").src = blobUrl;
      this.user = {};
    },
    async gerarPdf() {
      
      const pdfDoc = await PDFDocument.create();

      const [jpgImageBytes, fontUnb, fontUnbNormal] = await Promise.all([
        fetchBinaryAsset(image),
        fetchBinaryAsset(unb),
        fetchBinaryAsset(unbNormal),

      ]);

      const pngImage = await pdfDoc.embedPng(jpgImageBytes);

      pdfDoc.registerFontkit(fontkit);
      const customFont = await pdfDoc.embedFont(fontUnb);
      const customFontNormal = await pdfDoc.embedFont(fontUnbNormal);


      const textSize = 21;

      const text = this.user.nome;

      const textWidth = customFont.widthOfTextAtSize(text, textSize);

      const text2 = this.user.curso;

      const textWidth2 = customFont.widthOfTextAtSize(text2, textSize);

      const text3 = this.user.modalidade;

      const textWidth3 = customFont.widthOfTextAtSize(text3, textSize);


      const page = pdfDoc.addPage();

      const { height } = page.getSize();

      // if (this.user.mod) {
      //   const pag2 = pdfDoc.addPage();

      //   for (const [index, mod] of this.user.mod.entries()) {
      //     let eixoYMod =
      //       eixoYLess && eixoYLess < 800 - index * 30
      //         ? eixoYLess - 30
      //         : 800 - index * 30;
      //     pag2.drawText(mod.name, {
      //       x: 50,
      //       y: eixoYMod,
      //       size: 12,
      //       font: customFont,
      //       color: rgb(0, 0, 0)
      //     });

      //     let eixoYLess;
      //     for (const [inde, lesson] of mod.lessons.entries()) {
      //       eixoYLess = eixoYMod - 15 - 15 * inde;
      //       pag2.drawText("- " + lesson.name, {
      //         x: 55,
      //         y: eixoYMod - 15 - 15 * inde,
      //         size: 10,
      //         font: customFont,
      //         color: rgb(0, 0, 0)
      //       });
      //     }
      //   }
      // }

      page.drawImage(pngImage, {
        y: 845,
        x: -19,
        width: 850,
        height: 623,
        rotate: degrees(-90)
      });

      page.drawText(text, {
        x: 370,
        y: (height - textWidth) / 2 + (textWidth - 25),
        size: textSize,
        font: customFont,
        color: rgb(0, 0, 0),
        rotate: degrees(-90)
      });

      page.drawText(this.user.cpf.toString(), {
        x: 337,
        y: 585,
        size: 20.5,
        font: customFont,
        color: rgb(0, 0, 0),
        rotate: degrees(-90)
      });

      page.drawText(text2, {
        x: 275,
        y: (height - textWidth2) / 2 + (textWidth2 - 25),
        size: textSize,
        font: customFont,
        color: rgb(0, 0, 0),
        rotate: degrees(-90)
      });

      page.drawText(text3, {
        x: 210,
        y: (height - textWidth3) / 2 + (textWidth3 - 25),
        size: textSize,
        font: customFont,
        color: rgb(0, 0, 0),
        rotate: degrees(-90)
      });

      page.drawText(this.user.baseLegal.toString(), {
        x: 151,
        y: 465,
        size: 15,
        font: customFontNormal,
        color: rgb(0, 0, 0),
        rotate: degrees(-90)
      });

      page.drawText(new Date(this.user.data).toLocaleDateString("pt-BR"), {
        x: 82,
        y: 535,
        size: 14,
        font: customFont,
        color: rgb(0, 0, 0),
        rotate: degrees(-90)
      });

      page.drawText(this.user.exame.toString(), {
        x: 82,
        y: 700,
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
        rotate: degrees(-90)
      });

      const pdfBytes = await pdfDoc.save();
      this.renderInIframe(pdfBytes);
    }
  },
  watch: {
    user() {
      this.gerarPdf();
    }
  }
};
</script>

<style>
</style>
